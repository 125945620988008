import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OAuth from "../comps/oauth";
import { GlobalContext } from "../GlobalContext";

export default function Signup() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { alert, dispatch, setLoading } = useContext(GlobalContext);

  const [uid, setUid] = useState("");
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signupAccount = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, password }),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        setUid(data);
      } else {
        alert({ type: "warning", title: "Warning !", text: error });
      }
    } catch (error) {
      alert({ type: "danger", title: "Error !", text: error.message });
    } finally {
      setLoading(false);
    }
  };

  const verifyAccount = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/verify`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid, otp }),
      });
      const { data, error } = await res.json();
      if (res.ok) {
        const { message, token } = data;
        if (token) {
          dispatch({ type: "SIGNIN", payload: token });
          navigate(state ?? "/dashboard");
        }
        if (message) {
          alert({ type: "warning", title: "Warning !", text: message });
        }
      } else {
        setUid("");
        alert({ type: "warning", title: "Warning !", text: error });
      }
    } catch (error) {
      alert({ type: "danger", title: "Error !", text: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container container-normal py-4">
      <div className="row align-items-center g-4">
        <div className="col-lg">
          {Boolean(uid) ? (
            <div className="container-tight">
              <div className="card card-md">
                <div className="card-body">
                  <h2 className="h2 text-center mb-4">Verify Your Email</h2>
                  <form onSubmit={verifyAccount} autoComplete="off">
                    <p className="text-muted mb-4">
                      We have sent an OTP code to{" "}
                      <b>
                        <u>{email}</u>
                      </b>
                      . Check your email to get the code and enter it below. The
                      code is only valid for 10 minutes and you have 3 attempts
                      to enter it correctly.
                    </p>
                    <div className="mb-3">
                      <label className="form-label">OTP Code</label>
                      <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="form-control"
                        placeholder="Enter OTP Code"
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="form-footer">
                      <button type="submit" className="btn btn-primary w-100">
                        Verify Email
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="container-tight">
              <div className="card card-md">
                <div className="card-body">
                  <h2 className="h2 text-center mb-4">Create new account</h2>
                  <form onSubmit={signupAccount} autoComplete="off">
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        placeholder="Enter Name"
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Email address</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        placeholder="Enter Email"
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <div className="input-group input-group-flat">
                        <input
                          type={show ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          placeholder="Your password"
                          autoComplete="off"
                          required
                        />
                        <span className="input-group-text">
                          <Link
                            tabIndex={-1}
                            onClick={() => setShow((s) => !s)}
                            className="link-secondary ms-1"
                            data-bs-toggle="tooltip"
                            aria-label="Show password"
                            data-bs-original-title="Show password"
                          >
                            <svg
                              className="icon"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx={12} cy={12} r={2} />
                              <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                            </svg>
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div className="form-footer">
                      <button type="submit" className="btn btn-primary w-100">
                        Create Account
                      </button>
                    </div>
                  </form>
                </div>
                {/* <div className="hr-text">or</div>
                <OAuth /> */}
              </div>
              <div className="text-center text-muted mt-3">
                Already have account?{" "}
                <Link to="/signin" state={state} tabIndex={-1}>
                  Sign in
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg d-none d-lg-block">
          <img
            src="./static/illustrations/undraw_secure_login_pdn4.svg"
            className="d-block mx-auto"
            alt="illustration"
            height={300}
          />
        </div>
      </div>
    </div>
  );
}
