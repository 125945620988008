import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer footer-transparent d-print-none">
      <div className="container-xl">
        <div className="row text-center align-items-center flex-row-reverse">
          <div className="col-12 col-lg-auto mt-3 mt-lg-0">
            <ul className="list-inline list-inline-dots mb-0">
              <li className="list-inline-item">
                Copyright © 2023
                <Link to="." className="link-secondary">
                  | SMJV Pariwar Foundation
                </Link>
                | All rights reserved.
              </li>
              <li className="list-inline-item">
                <Link to="/terms" className="link-secondary">
                  Terms & Conditions
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/refunds" className="link-secondary">
                  Refund Policy
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/privacy" className="link-secondary">
                  Privacy Policy
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/shipping" className="link-secondary">
                  Shipping Policy
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/contact" className="link-secondary">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
