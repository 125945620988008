import React from "react";

export default function ContactUs() {
  return (
    <div className="container py-4 " style={{ height: "85vh" }}>
      <div className="row justify-content-center">
        <div className="col-lg-8 text-center">
          <h3 className="home-title mt-3">Contact Us</h3>
          <p className="mt-4">
            <strong>SMJV Pariwar Foundation</strong>
          </p>
          <p>844, Agarkar Rd, Next to BMCC,</p>
          <p>Deccan, Pune - 411004</p>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@smjvpune.com">info@smjvpune.com</a>
          </p>
          <p>
            <strong>Phone:</strong> <a href="tel:+919422008290">9422008290</a>
          </p>
        </div>
      </div>
    </div>
  );
}
