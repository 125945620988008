import { Link } from "react-router-dom";

export default function Privacy() {
  return (
    <div className="container py-4">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <h3 className="home-title mt-3 text-center">Privacy Policy</h3>
          <div className="text-justify">
            <h2>1. Introduction</h2>
            <p>
              Welcome to the SMJV website (hereinafter referred to as “we,”
              “us,” or “our”). Your privacy is important to us. This Privacy
              Policy explains how we collect, use, disclose, and safeguard your
              information when you visit our website [your-website-url]. Please
              read this policy carefully. If you do not agree with the terms of
              this Privacy Policy, please do not access the site.
            </p>

            <h2>2. Information We Collect</h2>
            <p>
              We may collect information about you in a variety of ways. The
              information we may collect includes:
            </p>
            <ul>
              <li>
                <strong>Personal Data:</strong> Personally identifiable
                information, such as your name, email address, phone number, and
                demographic information that you voluntarily give to us when you
                register with the site or when you choose to participate in
                various activities related to the site.
              </li>
              <li>
                <strong>Derivative Data:</strong> Information our servers
                automatically collect when you access the site, such as your IP
                address, your browser type, your operating system, your access
                times, and the pages you have viewed directly before and after
                accessing the site.
              </li>
              <li>
                <strong>Financial Data:</strong> Financial information, such as
                data related to your payment method (e.g., valid credit card
                number, card brand, expiration date) that we may collect when
                you purchase, order, return, exchange, or request information
                about our services from the site.
              </li>
              <li>
                <strong>Social Media Data:</strong> User information from social
                networking sites, such as Facebook, Twitter, Instagram, and
                LinkedIn, including your name, your social media username, and
                any other information you choose to make public.
              </li>
            </ul>

            <h2>3. Use of Your Information</h2>
            <p>We may use the information we collect in the following ways:</p>
            <ul>
              <li>To deliver the services you request.</li>
              <li>To improve your experience with the site.</li>
              <li>
                To communicate with you, including responding to your inquiries
                and providing customer support.
              </li>
              <li>To process your transactions and manage your orders.</li>
              <li>
                To send you marketing and promotional information that we
                believe may be of interest to you.
              </li>
              <li>
                To improve our website and services based on your feedback.
              </li>
            </ul>

            <h2>4. Disclosure of Your Information</h2>
            <p>
              We may share information we have collected about you in certain
              situations, including:
            </p>
            <ul>
              <li>
                <strong>By Law or to Protect Rights:</strong> If we believe the
                release of information is necessary to comply with legal
                obligations, enforce our policies, or protect the rights,
                property, or safety of others.
              </li>
              <li>
                <strong>Business Transfers:</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
              <li>
                <strong>Third-Party Service Providers:</strong> We may share
                your information with third-party service providers that perform
                services on our behalf, such as payment processing, data
                analysis, email delivery, hosting services, and customer
                service.
              </li>
            </ul>

            <h2>5. Security of Your Information</h2>
            <p>
              We use administrative, technical, and physical security measures
              to help protect your personal information. While we have taken
              reasonable steps to secure the personal information you provide to
              us, please be aware that despite our efforts, no security measures
              are perfect or impenetrable, and no method of data transmission
              can be guaranteed against any interception or other type of
              misuse.
            </p>

            <h2>6. Your Data Protection Rights</h2>
            <p>
              Depending on your location, you may have the following rights
              regarding your personal data:
            </p>
            <ul>
              <li>
                <strong>Access:</strong> You have the right to request a copy of
                the information we hold about you.
              </li>
              <li>
                <strong>Correction:</strong> You can request that we correct any
                inaccuracies in your personal information.
              </li>
              <li>
                <strong>Deletion:</strong> You may request that we delete your
                personal information, subject to certain conditions.
              </li>
              <li>
                <strong>Objection:</strong> You have the right to object to the
                processing of your personal information under certain
                circumstances.
              </li>
            </ul>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on
              this page and updating the “Last Updated” date. You are advised to
              review this Privacy Policy periodically for any changes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
