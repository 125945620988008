import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="page-body">
      <div className="container-xl">
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-header justify-content-center">
                <div className="empty">
                  <div className="empty-img">
                    <img src="/logo.jpg" alt="logo" height={128} />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="mb-3 text-center">
                  <Link to="/signin" className="btn btn-success">
                    Sign In
                  </Link>
                </div>
                <div className="mb-3 text-center">
                  <Link to="/signup" className="btn btn-primary">
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row row-cards">
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery01.JPG"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery03.JPG"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery04.JPG"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery05.jpg"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery06.jpg"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery07.jpg"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery08.jpg"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery09.jpg"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery10.jpg"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery11.jpg"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-sm">
                  <span className="d-block">
                    <img
                      src="/images/gallery/gallery02.jpg"
                      alt="gallery"
                      className="card-img-top"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
